<template>
  <div class="p-3">
    <div>
      <app-title-content></app-title-content>
    </div>
    <div>
      <div class="row my-3">
        <div class="col-5">
          <select2
            :options="statusList"
            v-model="statusSelect"
            class="select-custom"
            placeholder="สถานภาพ"
            :settings="{
              language: {
                noResults: function () {
                  return 'ไม่พบข้อมูล';
                },
              },
              allowClear: 'true',
            }"
          ></select2>
        </div>
        <div class="col-5">
          <select2
            :options="typeList"
            v-model="typeSelect"
            class="select-custom"
            placeholder="ประเภทของระบบประปา"
            :settings="{
              language: {
                noResults: function () {
                  return 'ไม่พบข้อมูล';
                },
              },
              allowClear: 'true',
            }"
          ></select2>
        </div>
        <div class="col-2">
          <button class="btn btn-save w-100" @click="search()">ค้นหา</button>
        </div>
      </div>
      <div class="box-shadow p-4 mt-3">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-end">
            <span
              class="
                font-darkblue
                weight-700
                fontsize-h3
                mr-3
                d-flex
                align-items-center
              "
              >รายการข้อมูลประปาถ่ายโอน</span
            >
            <span class="fontsize-h5">จำนวน {{ total | toCommas }} รายการ</span>
          </div>

          <div class="d-flex">
            <button
              class="btn btn-addlist d-flex mr-3"
              @click="
                isShowModal = true;
                isEdit = false;
              "
            >
              <iconify
                icon="akar-icons:circle-plus-fill"
                class="fontsize-h5 mr-2"
              />
              เพิ่มข้อมูลประปาถ่ายโอน
            </button>
            <b-overlay :show="isLoad">
              <button
                class="btn btn-download d-flex align-items-center"
                @click="exportExcel"
              >
                <iconify icon="uiw:file-excel" class="fontsize-h4 mr-2" />
                Export to Excel
              </button>
            </b-overlay>
          </div>
        </div>
        <b-table
          id="my-table"
          hover
          borderless
          :no-border-collapse="true"
          :items="items"
          :fields="fields"
          show-empty
          :busy.sync="isBusy"
          class="table-custom box-shadow mt-3"
        >
          <template v-slot:empty="">
            <div class="text-center fontsize-h4 my-4">ไม่พบข้อมูล</div>
          </template>

          <div slot="table-busy" class="text-center font-blueinfo my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="pl-2">กำลังโหลด...</strong>
          </div>
          <template v-slot:head()="data">
            <div
              :class="
                data.field.align === 'center' ? 'text-center' : 'text-left'
              "
            >
              {{ data.label }}
            </div>
          </template>
          <template v-slot:cell()="data">
            <div
              :class="
                data.field.align === 'center' ? 'text-center' : 'text-left'
              "
            >
              {{ data.value }}
            </div>
          </template>
          <template v-slot:cell(index)="data">
            <div class="text-center">
              {{ (currentPage - 1) * perPage + (data.index + 1) }}.
            </div>
          </template>
          <template #cell(edit)="row">
            <div class="text-center cursor-pointer">
              <font-awesome-icon
                :icon="['fas', 'edit']"
                @click="editRow(row.item.transferSupplyID)"
                class="font-yellow"
              />
            </div>
          </template>
          <template #cell(delete)="row">
            <div class="text-center cursor-pointer">
              <font-awesome-icon
                :icon="['fas', 'trash']"
                @click="deleteRow(row.item.transferSupplyID)"
                class="font-reddanger"
              />
            </div>
          </template>
        </b-table>
        <b-pagination
          @change="changePage($event)"
          class="pagination-custom mt-3"
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
        ></b-pagination>
      </div>
    </div>
    <ModalAdd
      :modalShow="isShowModal"
      :isEdit="isEdit"
      :pwaId="pwaId"
      @change="isShowModal = $event"
      @reloadDataGrid="getListTransfer"
    ></ModalAdd>
  </div>
</template>

<script>
import {
  FilterService,
  TransferSupplyService,
} from "@/services/main.service.js";
import ModalAdd from "./modalAdd.vue";
export default {
  name: "transfer-supply",
  components: {
    ModalAdd,
  },
  data() {
    return {
      isLoad: false,
      apiURL: null,
      currentPage: 1,
      perPage: 50,
      total: 0,
      isBusy: false,
      isShowModal: false,
      isEdit: false,
      pwaId: null,
      statusList: [],
      statusSelect: null,
      typeList: [],
      typeSelect: null,
      sourceName: null,

      items: [],
      fields: [
        {
          key: "index",
          label: "ลำดับ",
          align: "center",
        },
        {
          key: "departmentName",
          label: "สทภ.",
          sortable: false,
        },
        {
          key: "villageName",
          label: "หมู่บ้าน",
          sortable: false,
        },
        {
          key: "villageNo",
          label: "หมู่",
          sortable: false,
        },
        {
          key: "tambolName",
          label: "ตำบล",
          sortable: false,
        },
        {
          key: "amphorName",
          label: "อำเภอ",
          sortable: false,
        },
        {
          key: "provinceName",
          label: "จังหวัด",
          sortable: false,
        },
        {
          key: "typeName",
          label: "ประเภท/ขนาด",
          sortable: false,
        },
        {
          key: "departmentBuildName",
          label: "หน่วยงานที่สร้าง",
          sortable: false,
        },
        {
          key: "deparTransferName",
          label: "หน่วยงานที่โอนให้",
          sortable: false,
        },
        {
          key: "statusName",
          label: "สถานภาพ",
          sortable: false,
        },
        {
          key: "edit",
          label: "แก้ไข",
          sortable: false,
          align: "center",
        },
        {
          key: "delete",
          label: "ลบ",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  created() {
    this.getListTransfer();
    this.getStatusType();
    this.getTransferType();
    this.apiURL = process.env.VUE_APP_BASE_API_DOWNLOAD;
  },
  mounted() {},
  methods: {
    search() {
      this.currentPage = 1;
      this.getListTransfer();
    },
    deleteRow(id) {
      let param = {
        transferSupplyID: id,
      };
      this.alertDeleteForm().then(async (result) => {
        if (result) {
          await TransferSupplyService.deleteTransfer(param)
            .then((res) => {
              if (res && res?.resData.status) {
                this.alertSuccess("ลบรายการสำเร็จ");
                this.getListTransfer();
              } else {
                this.alertFail("ลบรายการไม่สำเร็จ");
              }
            })
            .catch((err) => {
              this.alertFail("ลบรายการไม่สำเร็จ");
            });
        }
      });
    },

    editRow(id) {
      this.isShowModal = true;
      this.isEdit = true;
      this.pwaId = id;
    },

    async getListTransfer() {
      this.isBusy = true;
      let param = {
        NumPage: this.currentPage,
        NumRow: this.perPage,
        sourceName: this.sourceName,
        status: this.statusSelect,
        sourceTypeID: this.typeSelect,
      };
      await TransferSupplyService.searchTransfer(param)
        .then((res) => {
          this.items = res.data.map((m, i) => {
            return {
              ...m,
            };
          });
          this.total = res.total;
        })
        .catch((err) => {
          this.items = [];
          this.total = 0;
          this.currentPage = 1;
        })
        .finally((f) => {
          this.isBusy = false;
        });
    },
    async getStatusType() {
      await FilterService.getFilterStatusType().then((res) => {
        this.statusList = res.map((m) => {
          return { ...m };
        });
      });
    },
    async getTransferType() {
      await FilterService.getFilterTransferType().then((res) => {
        this.typeList = res.map((m) => {
          return { ...m };
        });
      });
    },
    async changePage(event) {
      this.currentPage = event;

      await this.getListTransfer();
    },
    async exportExcel() {
      this.isLoad = true;
      let param = {
        status: this.statusSelect,
        sourceTypeID: this.typeSelect,
      };
      await TransferSupplyService.exportExcelTransfer(param)
        .then((res) => {
          let file = `${this.apiURL}/${res.data.path}`;
          try {
            window.open(file, "_blank");
            this.isLoad = false;
          } catch (e) {
            this.isLoad = false;
            this.alertDownloadFail();
          }
        })
        .catch((err) => {
          this.isLoad = false;
          this.alertDownloadFail();
        });
    },
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
};
</script>

<style></style>
