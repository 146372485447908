<template>
  <div id="addTransferSupply">
    <b-modal
      id="my-modal1"
      v-model="showModal"
      modal-class="custom-modal-defualt"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      size="xl"
      @change="onChange"
      @shown="show"
      @ok="ok"
      @cancel="cancel"
    >
      <template #modal-title="{}">
        <font-awesome-icon
          v-if="isEdit === false"
          :icon="['fas', 'plus-circle']"
          class="mr-1"
        />
        <font-awesome-icon v-else :icon="['fas', 'edit']" class="mr-1" />
        {{ headerText }}
      </template>
      <template v-slot:default="">
        <b-overlay :show="isLoad">
          <div class="headline-list d-flex">
            <span class="py-2 weight-700">ข้อมูลแหล่งประปา</span>
          </div>
          <div class="row mt-3">
            <div class="col-4">
              <div class="row d-flex align-items-center">
                <div class="col-6 text-right px-0">จังหวัด :</div>
                <div class="col-6" tabindex="-1">
                  <select2
                    tabindex="2"
                    class="select-custom"
                    :options="provinceList"
                    v-model="proviceSelect"
                    placeholder="จังหวัด"
                    :settings="{
                      language: {
                        noResults: function () {
                          return 'ไม่พบข้อมูล';
                        },
                      },
                    }"
                    @change="changeProvince($event)"
                  >
                  </select2>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="row d-flex align-items-center">
                <div class="col-6 text-right px-0">อำเภอ :</div>
                <div class="col-6">
                  <select2
                    class="select-custom"
                    :options="amphurList"
                    v-model="amphurSelect"
                    placeholder="อำเภอ"
                    :settings="{
                      language: {
                        noResults: function () {
                          return 'ไม่พบข้อมูล';
                        },
                      },
                    }"
                    @change="getTambol($event)"
                  >
                  </select2>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="row d-flex align-items-center">
                <div class="col-6 text-right px-0">ตำบล :</div>
                <div class="col-6">
                  <select2
                    class="select-custom"
                    :options="tambolList"
                    v-model="tambolSelect"
                    placeholder="ตำบล"
                    :settings="{
                      language: {
                        noResults: function () {
                          return 'ไม่พบข้อมูล';
                        },
                      },
                    }"
                    @change="getVillage($event)"
                  >
                  </select2>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-4">
              <div class="row d-flex align-items-center">
                <div class="col-6 text-right px-0">หมู่ที่ :</div>
                <div class="col-6">
                  <b-form-input
                    class="input-custom"
                    placeholder="หมู่ที่"
                    v-model="villageNo"
                    type="number"
                  ></b-form-input>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="row d-flex align-items-center">
                <div class="col-6 text-right px-0">ชื่อหมู่บ้าน / ชุมชน :</div>
                <div class="col-6">
                  <select2
                    class="select-custom"
                    :options="villageList"
                    v-model="villageSelect"
                    placeholder="ชื่อหมู่บ้าน / ชุมชน"
                    :settings="{
                      language: {
                        noResults: function () {
                          return 'ไม่พบข้อมูล';
                        },
                      },
                    }"
                  >
                  </select2>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="row d-flex align-items-center">
                <div class="col-6 text-right px-0">สทภ. :</div>
                <div class="col-6">
                  <b-form-input
                    disabled
                    class="input-custom"
                    placeholder="สทภ."
                    v-model="department.departmentName"
                  ></b-form-input>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5">
            <div class="d-flex headline-list">
              <div class="3 py-2">
                <span class="weight-700"> สถานที่ตั้ง </span>
                <span class="font-gray"
                  >(สามารถเลือกกำหนดค่าอย่างใดอย่างหนึ่ง)</span
                >
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <div class="blue-box">
                <div class="header weight-700">ค่า UTM</div>
                <div class="content">
                  <div class="row mt-3">
                    <div class="col-4">
                      <div class="row d-flex align-items-center">
                        <div class="col-6 text-right px-0">โซน :</div>
                        <div class="col-6">
                          <select2
                            class="select-custom"
                            :options="zoneList"
                            v-model="zoneSelect"
                            placeholder="โซน"
                            :settings="{
                              language: {
                                noResults: function () {
                                  return 'ไม่พบข้อมูล';
                                },
                              },
                              allowClear: 'true',
                            }"
                          >
                          </select2>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="row d-flex align-items-center">
                        <div class="col-6 text-right px-0">
                          พิกัดหมู่บ้าน X :
                        </div>
                        <div class="col-6">
                          <b-form-input
                            class="input-custom"
                            placeholder="พิกัดหมู่บ้าน X"
                            v-model="coordinatesX"
                            type="number"
                          ></b-form-input>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="row d-flex align-items-center">
                        <div class="col-6 text-right px-0">
                          พิกัดหมู่บ้าน Y :
                        </div>
                        <div class="col-6">
                          <b-form-input
                            class="input-custom"
                            placeholder="พิกัดหมู่บ้าน Y"
                            v-model="coordinatesY"
                            type="number"
                          ></b-form-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <div class="blue-box">
                <div class="header weight-700">ค่าละติจูด / ลองจิจูด</div>
                <div class="content">
                  <div class="row mt-3">
                    <div class="col-4">
                      <div class="row d-flex align-items-center">
                        <div class="col-6 text-right px-0">ละติจูด :</div>
                        <div class="col-6">
                          <b-form-input
                            class="input-custom"
                            placeholder="ละติจูด"
                            v-model="latitude"
                            type="number"
                          ></b-form-input>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="row d-flex align-items-center">
                        <div class="col-6 text-right px-0">ลองจิจูด :</div>
                        <div class="col-6">
                          <b-form-input
                            class="input-custom"
                            placeholder="ลองจิจูด"
                            v-model="longitude"
                            type="number"
                          ></b-form-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5">
            <div class="d-flex headline-list">
              <div class="py-2">
                <span class="weight-700"> ข้อมูลทั่วไป </span>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-4">
              <div class="row d-flex align-items-center">
                <div class="col-6 text-right px-0">ประเภท / ขนาด :</div>
                <div class="col-6">
                  <select2
                    class="select-custom"
                    :options="sourceTypeList"
                    v-model="sourceTypeSelect"
                    placeholder="ประเภท / ขนาด"
                    :settings="{
                      language: {
                        noResults: function () {
                          return 'ไม่พบข้อมูล';
                        },
                      },
                      allowClear: 'true',
                    }"
                  >
                  </select2>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="row d-flex align-items-center">
                <div class="col-6 text-right px-0">หน่วยงานที่สร้าง :</div>
                <div class="col-6">
                  <b-form-input
                    class="input-custom"
                    placeholder="หน่วยงานที่สร้าง"
                    v-model="departmentBuildName"
                  ></b-form-input>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="row d-flex align-items-center">
                <div class="col-6 text-right px-0">ปีงบประมาณ :</div>
                <div class="col-6">
                  <b-form-input
                    class="input-custom"
                    placeholder="ปีงบประมาณ"
                    v-model="inYear"
                    type="number"
                  ></b-form-input>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-4">
              <div class="row d-flex align-items-center">
                <div class="col-6 text-right px-0">หน่วยงานที่โอนให้ :</div>
                <div class="col-6">
                  <b-form-input
                    class="input-custom"
                    placeholder="หน่วยงานที่โอนให้"
                    v-model="departmeTransferName"
                  ></b-form-input>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="row d-flex align-items-center">
                <div class="col-6 text-right px-0">ปีที่ถ่ายโอน :</div>
                <div class="col-6">
                  <b-form-input
                    class="input-custom"
                    placeholder="ปีที่ถ่ายโอน"
                    v-model="inYearTransfer"
                    type="number"
                  ></b-form-input>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="row d-flex align-items-center">
                <div class="col-6 text-right px-0">สถานภาพ :</div>
                <div class="col-6">
                  <select2
                    class="select-custom"
                    :options="statusTypeList"
                    v-model="statusTypeSelect"
                    placeholder="สถานภาพ"
                    :settings="{
                      language: {
                        noResults: function () {
                          return 'ไม่พบข้อมูล';
                        },
                      },
                    }"
                  >
                  </select2>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-4">
              <div class="row d-flex align-items-center">
                <div class="col-6 text-right px-0">กำลังการผลิต :</div>
                <div class="col-6">
                  <b-form-input
                    class="input-custom"
                    placeholder="กำลังการผลิต"
                    v-model="capacity"
                    type="number"
                  ></b-form-input>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row d-flex align-items-center">
                <div class="col-3 text-right px-0">เอกสารถ่ายโอนฯ :</div>
                <div class="col d-flex align-items-center">
                  <button
                    class="btn btn-check d-flex align-items-center mr-2"
                    @click="chooseFiles()"
                  >
                    <font-awesome-icon :icon="['fas', 'upload']" class="mr-1" />
                    อัพโหลดเอกสาร
                  </button>

                  <span class="font-gray"
                    >*รองรับไฟล์เอกสารประเภท .pdf เท่านั้น</span
                  >
                  <input
                    ref="fileUpload"
                    type="file"
                    @change="(val) => getFiles(val)"
                    hidden
                  />
                  <div class="pl-2" v-show="isHaveFile">
                    <span
                      class="cursor-pointer font-blueinfo"
                      style="text-decoration: underline"
                      @click="downloadFile()"
                      >(ดาวน์โหลดไฟล์แนบ)</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4"></div>
            <div class="col">
              <div class="row">
                <div class="col-3"></div>
                <div class="col">{{ fileName }}</div>
              </div>
            </div>
          </div>
        </b-overlay>
      </template>
      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex w-100 justify-content-center my-2">
          <b-overlay :show="isLoad">
            <button class="btn btn-outline-save" @click="cancel()">
              ยกเลิก
            </button>
          </b-overlay>
          <div class="mx-5"></div>
          <b-overlay :show="isLoad">
            <button type="submit" class="btn btn-save" @click="ok()">
              บันทึก
            </button>
          </b-overlay>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  FilterService,
  TransferSupplyService,
} from "@/services/main.service.js";
export default {
  props: {
    modalShow: {
      type: Boolean,
      default: () => false,
    },
    dataEdit: {
      default: () => null,
    },
    isEdit: {
      type: Boolean,
      default: () => false,
    },
    pwaId: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      isHaveFile: false,
      isLoad: false,
      apiURL: null,
      headerText: "",
      showModal: false,
      fileName: null,

      provinceList: [],
      proviceSelect: null,
      amphurList: [],
      amphurSelect: null,
      tambolList: [],
      tambolSelect: null,
      villageList: [],
      villageSelect: null,
      villageNo: null,
      department: { departmentID: null, departmentName: null },

      zoneList: [],
      zoneSelect: null,
      coordinatesX: null,
      coordinatesY: null,
      latitude: null,
      longitude: null,

      sourceTypeList: [],
      sourceTypeSelect: null,
      departmentBuildName: null,
      inYear: null,
      departmeTransferName: null,
      inYearTransfer: null,
      statusTypeList: [],
      statusTypeSelect: null,
      capacity: null,
      documentTransfer: null,
    };
  },
  created() {
    this.getProvince();
    this.getZone();
    this.getTransferTypeList();
    this.getStatusTypeList();
    this.apiURL = process.env.VUE_APP_BASE_API_DOWNLOAD;
  },
  methods: {
    fileUploaded() {
      if (this.documentTransfer && this.apiURL) {
        return `${this.apiURL}${this.documentTransfer}`;
      }

      return undefined;
    },
    downloadFile() {
      let file = `${this.apiURL}${this.documentTransfer}`;
      try {
        window.open(file, "_blank");
      } catch (e) {
        this.alertDownloadFail();
      }
    },
    onChange() {
      if (!this.showModal) {
        this.clearData();
      }
    },
    async ok(bvModalEvt) {
      this.isLoad = true;

      bvModalEvt.preventDefault();

      let formData = new FormData();
      let zone = this.zoneSelect
        ? this.zoneList.filter((f) => {
            return f.id === parseInt(this.zoneSelect);
          })[0]?.text
        : null;

      formData.append("provinceId", this.proviceSelect || "");
      formData.append("amphorId", this.amphurSelect || "");
      formData.append("tambolId", this.tambolSelect || "");
      formData.append("villageNo", this.villageNo || "");
      formData.append("villageId", this.villageSelect || "");
      formData.append("departmentID", this.department?.departmentID || "");

      formData.append("zoneID", zone || "");
      formData.append("coordinatesX", this.coordinatesX || "");
      formData.append("coordinatesY", this.coordinatesY || "");
      formData.append("latitude", this.latitude || "");
      formData.append("longitude", this.longitude || "");

      formData.append("typeID", this.sourceTypeSelect || "");
      formData.append("departmentBuildName", this.departmentBuildName || "");
      formData.append("inYear", this.inYear || "");
      formData.append("departmeTransferName", this.departmeTransferName || "");
      formData.append("inYearTransfer", this.inYearTransfer || "");
      formData.append("statusID", this.statusTypeSelect || "");
      formData.append("capacity", this.capacity || "");
      formData.append("documentTransfer", this.documentTransfer || "");

      if (!this.validate()) {
        this.alertFormValidate();
        this.isLoad = false;
      } else if (this.isEdit) {
        formData.append("transferSupplyID", this.pwaId);
        await TransferSupplyService.editTransfer(formData)
          .then((res) => {
            if (res.resData.status) {
              this.alertSuccess("แก้ไขข้อมูลประปาถ่ายโอนสำเร็จ").then(
                (result) => {
                  this.isLoad = false;
                  this.showModal = false;
                  this.clearData();
                  this.$emit("reloadDataGrid");
                }
              );
            } else {
              this.isLoad = false;
              this.alertFail("แก้ไขข้อมูลประปาถ่ายโอนไม่สำเร็จ");
            }
          })
          .catch((err) => {
            this.isLoad = false;
            this.alertFail("แก้ไขข้อมูลประปาถ่ายโอนไม่สำเร็จ");
          });
      } else {
        await TransferSupplyService.insertTransfer(formData)
          .then((res) => {
            if (res.resData.status) {
              this.alertSuccess("เพิ่มข้อมูลประปาถ่ายโอนสำเร็จ").then(
                (result) => {
                  this.isLoad = false;
                  this.showModal = false;
                  this.clearData();
                  this.$emit("reloadDataGrid");
                }
              );
            } else {
              this.isLoad = false;
              this.alertFail("เพิ่มข้อมูลประปาถ่ายโอนไม่สำเร็จ");
            }
          })
          .catch((err) => {
            this.isLoad = false;
            this.alertFail("เพิ่มข้อมูลประปาถ่ายโอนไม่สำเร็จ");
          });
      }
    },
    validate() {
      if (this.proviceSelect === null) return false;
      if (this.amphurSelect === null) return false;
      if (this.tambolSelect === null) return false;
      if (this.villageNo === null || this.villageNo === "") return false;
      if (this.villageSelect === null) return false;
      if (this.department?.departmentID === null) return false;

      if (
        this.zoneSelect === null ||
        this.coordinatesX === null ||
        this.coordinatesY === null ||
        this.zoneSelect === "" ||
        this.coordinatesX === "" ||
        this.coordinatesY === ""
      ) {
        if (
          this.latitude === null ||
          this.longitude === null ||
          this.latitude === "" ||
          this.longitude === ""
        )
          return false;
      }

      if (this.sourceTypeSelect === null) return false;
      if (this.departmentBuildName === null || this.departmentBuildName === "")
        return false;
      if (this.inYear === null || this.inYear === "") return false;
      if (
        this.departmeTransferName === null ||
        this.departmeTransferName === ""
      )
        return false;
      if (this.inYearTransfer === null || this.inYearTransfer === "")
        return false;
      if (this.statusTypeSelect === null) return false;

      return true;
    },
    cancel() {
      this.clearData();
    },
    clearData() {
      this.proviceSelect = null;
      this.amphurList = [];
      this.amphurSelect = null;
      this.tambolList = [];
      this.tambolSelect = null;
      this.villageList = [];
      this.villageSelect = null;
      this.villageNo = null;
      this.department = { departmentID: null, departmentName: null };

      this.zoneSelect = null;
      this.coordinatesX = null;
      this.coordinatesY = null;
      this.latitude = null;
      this.longitude = null;

      this.sourceTypeSelect = null;
      this.departmentBuildName = null;
      this.inYear = null;
      this.departmeTransferName = null;
      this.inYearTransfer = null;
      this.statusTypeSelect = null;
      this.capacity = null;
      this.documentTransfer = null;

      this.fileName = null;
      this.isHaveFile = false;

      this.$emit("change", this.showModal);
    },
    show() {
      const el = document.querySelector("#my-modal1");
      const test = el.querySelectorAll(".modal-content");
      test[0].removeAttribute("tabindex");
    },
    chooseFiles() {
      this.$refs["fileUpload"].click();
    },
    getFiles(val) {
      const objFile = val.target.files[0];

      if (objFile) {
        this.documentTransfer = objFile;

        this.fileName = objFile.name;
      }
    },
    changeProvince(provinceId = null) {
      this.department.departmentID = null;
      this.department.departmentName = null;
      this.getAmphur(provinceId);
      if (provinceId) {
        this.getDepByProvince(provinceId);
      }
    },
    async getDepByProvince(provinceId) {
      await FilterService.getFilterDepByProvince(provinceId)
        .then((res) => {
          this.department.departmentID = res[0].id;
          this.department.departmentName = res[0].text;
        })
        .catch((err) => {
          this.department.departmentID = null;
          this.department.departmentName = null;
        });
    },
    async getProvince() {
      await FilterService.getListProvince()
        .then((res) => {
          this.provinceList = res;
        })
        .catch((err) => {
          this.provinceList = [];
        });
    },
    async getAmphur(provinceId = null) {
      this.amphurList = [];
      this.amphurSelect = null;
      this.tambolList = [];
      this.tambolSelect = null;
      this.villageList = [];
      this.villageSelect = null;
      await FilterService.getListAmphor(provinceId)
        .then((res) => {
          this.amphurList = res;
        })
        .catch((err) => {
          this.amphurList = [];
        });
    },
    async getTambol(amphorId = null) {
      this.tambolSelect = null;
      this.tambolList = [];
      this.villageList = [];
      this.villageSelect = null;
      await FilterService.getListTambol(amphorId)
        .then((res) => {
          this.tambolList = res;
        })
        .catch((err) => {
          this.tambolList = [];
        });
    },
    async getVillage(tambolId = null) {
      this.villageList = [];
      this.villageSelect = null;
      await FilterService.getListVillage(tambolId)
        .then((res) => {
          this.villageList = res;
        })
        .catch((err) => {
          this.villageList = [];
        });
    },
    async getTransferTypeList() {
      this.sourceTypeList = [];
      this.sourceTypeSelect = null;
      await FilterService.getFilterTransferType()
        .then((res) => {
          this.sourceTypeList = res;
        })
        .catch((err) => {
          this.sourceTypeList = [];
        });
    },
    async getStatusTypeList() {
      this.statusTypeList = [];
      this.statusTypeSelect = null;
      await FilterService.getFilterStatusType()
        .then((res) => {
          this.statusTypeList = res;
        })
        .catch((err) => {
          this.statusTypeList = [];
        });
    },
    async getZone() {
      await FilterService.getListZone()
        .then((res) => {
          this.zoneList = res;
        })
        .catch((err) => {
          this.zoneList = [];
        });
    },
  },
  watch: {
    modalShow: {
      handler: function (val, oldVal) {
        if (val) {
          this.headerText = "เพิ่มข้อมูลประปาถ่ายโอน";
          if (this.isEdit) {
            if (this.pwaId !== null) {
              this.headerText = "แก้ไขข้อมูลประปาถ่ายโอน";
              TransferSupplyService.getTransferByID(this.pwaId).then((res) => {
                this.proviceSelect = res.provinceID;
                this.changeProvince(this.proviceSelect);
                this.amphurSelect = res.amphorID;
                this.getTambol(this.amphurSelect);
                this.tambolSelect = res.tambolID;
                this.getVillage(this.tambolSelect);
                this.villageSelect = res.villageID;
                this.villageNo = res.villageNo;
                this.department.departmentName = res.departmentName;
                this.department.departmentID = res.departmentID;

                this.zoneSelect = res.zoneID
                  ? this.zoneList.filter((f) => {
                      return f.text === res.zoneID;
                    })[0]?.id
                  : null;
                this.coordinatesX = res.coordinatesX;
                this.coordinatesY = res.coordinatesY;
                this.latitude = res.latitude;
                this.longitude = res.longitude;

                this.sourceTypeSelect = res.typeID;
                this.departmentBuildName = res.departmentBuildName;
                this.inYear = res.inYear;
                this.departmeTransferName = res.departmeTransferName;
                this.inYearTransfer = res.inYearTransfer;
                this.statusTypeSelect = res.statusID;
                this.capacity = res.capacity;

                if (
                  res.documentTransfer !== "" &&
                  res.documentTransfer !== null
                ) {
                  this.documentTransfer = res.documentTransfer;
                  this.isHaveFile = true;
                } else {
                  this.documentTransfer = null;
                  this.isHaveFile = false;
                }
              });
            }
          }
          this.showModal = true;
        }
      },
    },

    deep: true,
  },
};
</script>

<style></style>
